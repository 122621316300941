const videos = document.querySelectorAll('.iframe-facede') as NodeListOf<HTMLDivElement>;
videos.forEach(v => {
  v.addEventListener("click", function(ev) {
    var iframe = document.createElement("iframe"),
      btn = this.querySelector(".if-button") as HTMLButtonElement,
      url = btn?.getAttribute("href") ?? btn?.dataset?.url;
    iframe.loading = "lazy";
    iframe.width="772";
    iframe.height="459";
    iframe.frameBorder="0";
    iframe.allowFullscreen = true;
    iframe.allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share";
    iframe.src = `${url}?autoplay=1&enablejsapi=1`;
    this.parentElement?.append(iframe);
    this.classList.add('opacity-0');
    //this.parentElement?.nextSibling?.querySelector(".heading").classList.add('hidden');
    //this.nextElementSibling.querySelector(".heading")?.classList.add('opacity-0');
    //return false;
  });
});