import { Tabs } from "flowbite";
import type { TabsOptions, TabsInterface, TabItem } from "flowbite";
const tabs = document.querySelectorAll(".avpt-tab") as NodeListOf<HTMLElement>;
tabs.forEach(t => {
  const optionlists = Array.from(t.querySelectorAll('.avpt-tab-select>option') as NodeListOf<HTMLOptionElement>);
  const tabElements: TabItem[] = optionlists.map((o, i) => {
    const items = t.querySelectorAll('.avpt-tab-item') as NodeListOf<HTMLElement>;
    const contents = t.querySelectorAll('.avpt-tab-content>*') as NodeListOf<HTMLElement>;
    return {
      id: o.value,
      triggerEl: items[i],
      targetEl: contents[i],
    } as TabItem
  });
  const tabs: TabsInterface = new Tabs(t, tabElements);
  const tab = document.querySelector("#tabs") as HTMLSelectElement;
  tab.onchange = (ev) => tabs.show(tab.value);
});